import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import "leaflet/dist/leaflet.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import displayId from "../../utils/displayId";
import "./map.css";
import {
  MARKETPLACE_COLOR_BLUE,
  PopupSubHeading,
  PopupText,
  PopupTextHighlighted,
  PopupTextLine,
} from "./marketplace-map-styles";

const OfferBuyMarker: React.FC<{
  org: Organisation;
  buyer: Site;
  site: OrgSiteListEntry | NetworkSite;
  sdmOffer: SdmOffer;
}> = ({ org, buyer, site, sdmOffer }) => {
  const navigate = useNavigate();

  const isNetworkSite = "isNetwork" in site && site.isNetwork;

  return (
    <div>
      <PopupText
        $fontWeight="0"
        $clickable={!isNetworkSite}
        onClick={
          isNetworkSite
            ? undefined
            : () => {
                navigate(`/org/${org.id}/site/${site.id}`);
              }
        }
      >
        {isNetworkSite
          ? `InRange network site ${displayId(site.id).substring(0, 3)}`
          : (site as OrgSiteListEntry).name}
      </PopupText>
      {isNetworkSite && (
        <PopupText $fontWeight="0" $fontSize="10px">
          {displayId(site.id)}
        </PopupText>
      )}
      <PopupSubHeading $color={MARKETPLACE_COLOR_BLUE}>
        Offering to sell
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(sdmOffer.config.volume, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatPercentage(
            sdmOffer.config.volume /
              (buyer.energyFlowAnnual.networkImport +
                buyer.energyFlowAnnual.gridImport)
          )}
        </PopupTextHighlighted>{" "}
        of your unmet demand
      </PopupTextLine>
      <PopupTextLine>
        <PopupTextHighlighted>
          {(sdmOffer.config.tariff * 100).toFixed(2)}
          {fractionalCurrencySymbol(site.currencyCode)}
        </PopupTextHighlighted>{" "}
        / kWh for {sdmOffer.config.ppaLength} years
      </PopupTextLine>
      {isNetworkSite && (
        <PopupText
          $fontWeight="0"
          $fontSize="12px"
          style={{ marginTop: "1.33em" }}
        >
          * Location is approximate
        </PopupText>
      )}
    </div>
  );
};

export default OfferBuyMarker;
