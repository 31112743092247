import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import {
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { calcSiteOwnershipForOrg } from "@inrange/theme-components";
import {
  formatCurrency,
  formatKiloUnitsWithValue,
  formatUnitsToNearestTen,
  fractionalCurrencySymbol,
  roundCurrency,
} from "@inrange/theme-components/formatting";

export const formatBuyDescription = (
  organisation: Organisation,
  site: Site,
  investmentModel: string,
  bestBuyOffer: SdmOffer,
  unmetDemand: boolean = false,
  procurementChartOverlay: boolean = false
): string => {
  if (
    !organisation ||
    !site ||
    !bestBuyOffer ||
    // ProposalSummary has a useEffect which defaults the investmentModel
    // if this hasn't run yet, or the value passed in here is "none", then
    // we need to return and not show an SDM match
    !investmentModel ||
    investmentModel === "none"
  ) {
    return "";
  }
  const numNetworkImportSites = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  ).length;
  const ownership = calcSiteOwnershipForOrg(site, organisation);
  // Treat "none" as "license"
  const savingsWithBuy = roundCurrency(
    ownership === "ownerOccupier"
      ? bestBuyOffer.financialModels[ownership][investmentModel].savings
      : bestBuyOffer.financialModels[ownership].savings
  );
  const savingsWithoutBuy = roundCurrency(
    ownership === "ownerOccupier"
      ? site.financialModels[ownership][investmentModel].savings
      : site.financialModels[ownership].savings
  );
  const extraSavings = savingsWithBuy - savingsWithoutBuy;
  if (extraSavings <= 0) return "";

  const totalSiteDemand = bestBuyOffer.config.volume;

  if (unmetDemand) {
    if (numNetworkImportSites > 0) {
      return `Buy ${formatUnitsToNearestTen(
        totalSiteDemand,
        "kWh"
      )} more of unmet demand from the InRange network`;
    }

    return `Buy at least ${formatUnitsToNearestTen(
      totalSiteDemand,
      "kWh"
    )} of unmet demand from the InRange network`;
  }

  if (procurementChartOverlay) {
    // We only show the overlay when there are no existing matches
    return `You can save at least ${formatCurrency(
      extraSavings,
      site.currencyCode
    )} on your existing energy procurement by buying energy from the InRange network`;
  }

  if (numNetworkImportSites > 0) {
    return `Save ${formatCurrency(
      extraSavings,
      site.currencyCode
    )} more by buying energy from the InRange network`;
  }

  return `Save at least ${formatCurrency(
    extraSavings,
    site.currencyCode
  )} by buying energy from the InRange network`;
};

export const formatSellDescription = (
  bestSellOffer: SdmOffer,
  currencyCode: string
): string => {
  if (!bestSellOffer) return "";
  const volumeDescription = formatKiloUnitsWithValue(
    bestSellOffer.config.volume,
    "Wh"
  );
  const tariffDescription = `${(bestSellOffer.config.tariff * 100).toFixed(2)} ${fractionalCurrencySymbol(currencyCode)}/kWh`;
  return `At least ${volumeDescription} at ${tariffDescription} sellable in the Marketplace`;
};
