import { Site } from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils.ts";
import React from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import {
  ColorHighlighted,
  HiddenOfferBullet,
  OfferComparisonSectionBefore,
  OfferDetailHeader,
  OfferList,
} from "./marketplace-styles";
import { getIRR, getRevenue } from "./marketplace-utils";

const YourSiteTodayBeforeSellOffer: React.FC<{
  site: Site;
  renderBlankOfferRow: boolean;
}> = ({ site, renderBlankOfferRow }) => {
  const totalExportableEnergy =
    site.energyFlowAnnual.networkExport + site.energyFlowAnnual.exported;
  const networkExportSdmMatches = [
    ...site.sdmMatches,
    ...(site.energyFlowAnnual.exported > 0
      ? [
          {
            sellerId: site.id,
            buyerId: "spill",
            volume: site.energyFlowAnnual.exported,
            tariff: site.inrangeExportTariff,
          },
        ]
      : []),
  ]
    .filter((match) => match.sellerId === site.id)
    .sort(sortBy((match) => match.tariff));
  return (
    <OfferComparisonSectionBefore>
      <OfferDetailHeader>
        <ColorHighlighted>Your site today</ColorHighlighted>
      </OfferDetailHeader>
      <div>
        {totalExportableEnergy === 0 && <>0 kWh energy exported</>}
        {totalExportableEnergy > 0 && (
          <>
            {networkExportSdmMatches.length} buyer
            {networkExportSdmMatches.length > 1 ? "s" : ""} of 100% of your
            exportable energy
            <OfferList>
              {networkExportSdmMatches.map((match) => (
                <li key={match.buyerId}>
                  {formatUnitsToNearestTen(match.volume, "kWh")} @{" "}
                  {(match.tariff * 100).toFixed(2)}
                  {fractionalCurrencySymbol(site.currencyCode)}
                </li>
              ))}
              {renderBlankOfferRow && (
                <HiddenOfferBullet>&nbsp;</HiddenOfferBullet>
              )}
            </OfferList>
          </>
        )}
      </div>
      <div>
        {formatUnitsToNearestTen(site.energyFlowAnnual.behindMeter, "kWh")} sold
        on-site @ {(site.tenantTariff * 100).toFixed(2)}
        {fractionalCurrencySymbol(site.currencyCode)}
      </div>
      <div>{formatPercentage(getIRR(site, site.financialModels))} IRR</div>
      <div>
        {formatCurrencyToNearestTen(
          getRevenue(site, site.financialModels),
          site.currencyCode,
          0,
          false
        )}{" "}
        revenue / year
      </div>
    </OfferComparisonSectionBefore>
  );
};

export default YourSiteTodayBeforeSellOffer;
