export const getProcurementNote = (
  hasOnsiteBehindMeter: boolean,
  numNetworkImportSites: number
): string | undefined => {
  let procurementNote: string | undefined = undefined;
  if (hasOnsiteBehindMeter && numNetworkImportSites > 0) {
    procurementNote = `from on-site generation and ${numNetworkImportSites} network sites`;
  } else if (hasOnsiteBehindMeter) {
    procurementNote = "from on-site generation";
  } else if (numNetworkImportSites > 0) {
    procurementNote = `from ${numNetworkImportSites} network sites`;
  }
  return procurementNote;
};
