import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery, UseQueryResult } from "react-query";
import getSiteMarketplaceGraphs from "../http/getSiteMarketplaceGraphs";
import { SiteMarketplaceGraphs } from "../models/site";

const useSiteMarketplaceGraphs = ({
  siteId,
  extraBuyerId,
  extraSellerId,
  userOrgId,
}: {
  siteId: string;
  extraBuyerId: string | undefined;
  extraSellerId: string | undefined;
  userOrgId?: string;
}) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchSiteMarketplaceGraphs: UseQueryResult<
    { curves: SiteMarketplaceGraphs },
    unknown
  > = useQuery(
    ["site", siteId, "marketplace-graphs", extraBuyerId, extraSellerId],
    async () => {
      return getSiteMarketplaceGraphs(
        await getToken(),
        siteId,
        extraBuyerId,
        extraSellerId,
        userOrgId
      );
    },
    {
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
    }
  );

  return {
    fetchSiteMarketplaceGraphs,
  };
};

export default useSiteMarketplaceGraphs;
