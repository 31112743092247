import styled from "styled-components";
import theme from "../../styles/theme";
import SimpleButton from "../buttons/SimpleButton";

export const Description = styled.div`
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 10px;
`;

export const OfferDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: ${({ theme }) => theme.colors.grey200};
  padding: 14px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
`;

export const OfferDetailSection = styled.div`
  flex: 1;
  font-size: 14px;
`;

export const OfferDetailHeader = styled.div`
  margin-bottom: 6px;
  font-size: 16px;
`;

export const NoOfferComparison = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 50%;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: end;
`;

export const OfferComparison = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
`;

export const OfferComparisonSectionBefore = styled.div`
  flex: 1;
  font-size: 14px;
  background: ${({ theme }) => theme.colors.grey200};
  padding: 14px;
  border-radius: 8px;
  width: 100%;
`;

export const OfferComparisonSectionAfter = styled.div`
  flex: 1;
  font-size: 14px;
  background: #5486ac;
  padding: 14px;
  border-radius: 8px;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ColorHighlighted = styled.span`
  color: #2879a7;
  font-weight: bold;
`;

export const ColorHighlightedWhite = styled.span`
  color: white;
  font-weight: bold;
`;

export const OfferList = styled.ul`
  margin: 0;
  padding-inline-start: 0;
  list-style-position: inside;
`;

export const HiddenOfferBullet = styled.li`
  list-style-type: none;
`;

export const MarketplaceButton = styled(SimpleButton)<{ $width?: string }>`
  width: ${(props) => props.$width || "160px"};
  flex-shrink: 0;
  ${({ disabled }) =>
    disabled ? `border: 1px solid ${theme.colors.grey800}` : ""};
  ${({ disabled }) => (disabled ? `background: ${theme.colors.grey250}` : "")};
  opacity: 1;
`;

export const Confirmation = styled.div`
  font-size: 16px;
`;

export const InheritColorLink = styled.a`
  color: inherit;
  &:visited {
    color: inherit;
  }
`;
