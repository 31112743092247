import {
  useRelatedLandlordSitesList,
  useSite,
  useSiteSdm,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import {
  Column,
  Grid,
  PvSystemWarning,
  Row,
  SimpleButton,
  SummaryBoxMetric,
  SummaryBoxMetrics,
  SummaryBoxMetricsMulti,
} from "@inrange/theme-components";
import {
  GenerationVsDemandChart,
  ProposalSiteChart,
  YearDayEnergyChart,
} from "@inrange/theme-components/charts";
import {
  MarketplaceEnergyCurvesModal,
  siteCanBuy,
  siteCanSell,
} from "@inrange/theme-components/marketplace";
import {
  LandlordSiteMetricCards,
  LandlordSiteMetricValues,
  OwnerOccupierSiteMetricCards,
  TenantSiteMetricCards,
  TenantSiteMetricValues,
} from "@inrange/theme-components/site";
import { BarDatum } from "@nivo/bar";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../auth/UserContext";
import { formatBuyDescription, formatSellDescription } from "../../utils/sdm";
import {
  genEnergyProcuredChartData,
  genSiteGenerationConsumptionChartData,
} from "../dashboard/chartDataFormatting";
import generateProposalSummaryMetrics from "./generateProposalSummaryMetrics";
import LeaseLicenseChoiceSummary from "./LeaseLicenseChoiceSummary";
import { LeaseLicenseToggle } from "./LeaseLicenseToggle";
import NetworkSitesBar from "./NetworkSitesBar";

interface ProposalSummaryProps {
  site: Site;
  organisation: Organisation;
  irr?: number;
  ner: number;
  paybackMonths?: number;
  investmentModel: string;
  setInvestmentModel: (model: string) => void;
  isOwnerOccupier: boolean;
  isGeneratingSite: boolean;
  isNotYetProcuringSite: boolean;
  orgType: string;
}

const ProposalSummary: React.FC<ProposalSummaryProps> = ({
  site,
  organisation,
  irr,
  ner,
  paybackMonths,
  investmentModel,
  setInvestmentModel,
  isOwnerOccupier,
  isGeneratingSite,
  isNotYetProcuringSite,
  orgType,
}) => {
  const siteId = site.id;
  const orgId = organisation.id;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlSpillSiteId = params.get("spillSiteId") || undefined;
  const { user } = useContext(UserContext);
  const { updateSite } = useSite({ siteId, userOrgId: orgId, app: "customer" });
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] = useState<
    "year" | "summer" | "winter"
  >("year");
  const { fetchSiteSdmList, bestSellOffer, bestBuyOffer } = useSiteSdm({
    siteId,
    onlyLinkedOrgs: !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE,
    userOrgId: orgId,
  });
  const [showEnergyCurvesModal, setShowEnergyCurvesModal] =
    useState<boolean>(false);
  const isSiteCanSell = siteCanSell(organisation, site);
  const isSiteCanBuy = siteCanBuy(organisation, site);

  const isLandlord = orgType === "Landlord";
  const isBatterySite = !!site.batteryCapacity && !!site.batteryPower;

  // treat non generating sites as tenants
  const proposalType = isGeneratingSite
    ? isOwnerOccupier
      ? "ownerOccupier"
      : isLandlord
        ? "landlord"
        : "tenant"
    : "tenant";

  // Fetch landlord organisation data for tenant proposals
  const { fetchRelatedLandlordSitesList } = useRelatedLandlordSitesList(
    orgId,
    siteId,
    orgId
  );
  const sharedSitesWithLandlord = (
    fetchRelatedLandlordSitesList.data?.sites || []
  ).sort((a, b) => a.name.localeCompare(b.name));

  const updateInvestmentModel = (newInvestmentModel: string) => {
    updateSite.mutate({
      site: { investmentModel: newInvestmentModel, updateHubSpot: false },
    });
    setInvestmentModel(newInvestmentModel);
    trackUserInteraction(
      {
        organisation_name: organisation.name,
        site_id: site.id,
        site_name: site.name,
        site_operational_status: site.operationalStatus,
        new_investment_model: newInvestmentModel,
        old_investment_model: investmentModel,
      },
      "BASIC_ACTION",
      "UPDATE_INVESTMENT_MODEL",
      user!.email.toLowerCase(),
      "customer-app"
    );
  };

  const sellEnergyBlock =
    isSiteCanSell &&
    investmentModel === "license" &&
    proposalType !== "tenant" ? (
      <Column
        style={{ width: "90%" }}
        $margin={"0 15px 15px 15px"}
        $padding={"0 8px"}
        $justifyContent={"flex-end"}
      >
        <div
          style={{
            fontWeight: "500",
            fontSize: "14px",
            margin: "auto 0px",
          }}
        >
          {fetchSiteSdmList.isLoading && (
            <span>Finding a Marketplace sell offer…</span>
          )}
          {!fetchSiteSdmList.isLoading && (
            <>{formatSellDescription(bestSellOffer!, site.currencyCode)}</>
          )}
        </div>
        <SimpleButton
          fontWeight="400"
          label={"Sell more energy"}
          width={"60%"}
          onClick={() => {
            trackUserInteraction(
              {
                organisation_name: organisation.name,
                site_id: site.id,
                site_name: site.name,
                site_operational_status: site.operationalStatus,
                label: "Sell more energy",
                button_location: "site metrics tile",
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_SITE_BUTTON_CLICK",
              user!.email.toLowerCase(),
              "customer-app"
            );
          }}
          to={`/org/${orgId}/marketplace?siteId=${siteId}&offerType=sell`}
          fontColor={"#00022F"}
          background={"white"}
        />
      </Column>
    ) : undefined;

  const generateBuyEnergyBlock = (
    unmetDemand: boolean,
    chartOverlay = false
  ) => {
    return isSiteCanBuy && proposalType !== "landlord" ? (
      <Column
        style={{ width: "90%" }}
        $margin={"0 15px 15px 15px"}
        $padding={"0 8px"}
        $justifyContent={"flex-end"}
      >
        <div
          style={{
            fontWeight: "500",
            fontSize: "14px",
            margin: "auto 0px",
          }}
        >
          {fetchSiteSdmList.isLoading && (
            <span>Finding a Marketplace buy offer…</span>
          )}
          {!fetchSiteSdmList.isLoading && (
            <>
              {formatBuyDescription(
                organisation,
                site,
                investmentModel,
                bestBuyOffer!,
                unmetDemand,
                chartOverlay
              )}
            </>
          )}
        </div>
        <SimpleButton
          fontWeight="400"
          width={chartOverlay ? "35%" : "60%"}
          label={`Buy ${isNotYetProcuringSite ? "" : "more"} energy`}
          onClick={() => {
            trackUserInteraction(
              {
                organisation_name: organisation.name,
                site_id: site.id,
                site_name: site.name,
                site_operational_status: site.operationalStatus,
                label: `Buy ${isNotYetProcuringSite ? "" : "more"} energy`,
                button_location: "site metrics tile",
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_SITE_BUTTON_CLICK",
              user!.email.toLowerCase(),
              "customer-app"
            );
          }}
          to={`/org/${orgId}/marketplace?siteId=${siteId}&offerType=buy`}
          fontColor={chartOverlay ? "white" : "#00022F"}
          background={chartOverlay ? "#00022F" : "white"}
        />
      </Column>
    ) : undefined;
  };

  const noPvSystemSelected = !site.pvDesignSystemLastUpdatedAt;
  const hhDataUploaded = site.halfHourlyDemand.source === "hh_data";

  const metricsData = generateProposalSummaryMetrics(
    proposalType,
    isGeneratingSite,
    site,
    investmentModel,
    setInvestmentModel,
    hhDataUploaded,
    sharedSitesWithLandlord,
    sellEnergyBlock,
    generateBuyEnergyBlock,
    orgId,
    setShowEnergyCurvesModal,
    isSiteCanSell
  );
  const chartsHeight = "416px";

  const additionalChartData =
    proposalType === "landlord"
      ? genSiteGenerationConsumptionChartData(site)
      : proposalType === "tenant"
        ? genEnergyProcuredChartData(site, energyProcuredChartPeriod)
        : undefined;

  if (investmentModel === "none") {
    return (
      <LeaseLicenseChoiceSummary
        site={site}
        updateInvestmentModel={updateInvestmentModel}
        irr={irr}
        ner={ner}
        paybackMonths={paybackMonths}
        isOwnerOccupier={isOwnerOccupier}
      />
    );
  }

  const onShareHHDemandData = () => {
    trackUserInteraction(
      {
        organisation_name: organisation.name,
        site_id: site.id,
        site_name: site.name,
        site_operational_status: site.operationalStatus,
      },
      "BASIC_ACTION",
      "SHARE_HH_DEMAND_DATA",
      user!.email.toLowerCase(),
      "customer-app"
    );
  };

  const showPvSystemWarning = noPvSystemSelected || !hhDataUploaded;
  const showLeaseLicenseToggle =
    proposalType !== "tenant" && site.operationalStatus === "notStarted";

  return (
    <>
      <div data-testid={`sdm-data-loaded-${fetchSiteSdmList.isSuccess}`}></div>
      {(showPvSystemWarning || showLeaseLicenseToggle) && (
        <HeaderRow
          $justifyContent={
            !hhDataUploaded || noPvSystemSelected ? "space-between" : "flex-end"
          }
          $padding={"8px 12px 0px 12px"}
          $margin={"0"}
        >
          <PvSystemWarning
            hhDataUploaded={hhDataUploaded}
            noPvSystemSelected={noPvSystemSelected}
            isGeneratingSite={isGeneratingSite}
            name={site.name}
            onLinkClick={onShareHHDemandData}
          />
          <LeaseLicenseToggle
            investmentModel={investmentModel}
            setInvestmentModel={updateInvestmentModel}
            showLeaseLicenseToggle={showLeaseLicenseToggle}
          />
        </HeaderRow>
      )}
      {proposalType === "tenant" ? (
        <TenantSiteMetricCards
          metrics={metricsData.metricCards as TenantSiteMetricValues}
          currencyCode={site.currencyCode}
        />
      ) : proposalType === "ownerOccupier" ? (
        <OwnerOccupierSiteMetricCards
          metrics={metricsData.metricCards as LandlordSiteMetricValues}
          currencyCode={site.currencyCode}
        />
      ) : (
        <LandlordSiteMetricCards
          metrics={metricsData.metricCards as LandlordSiteMetricValues}
          currencyCode={site.currencyCode}
        />
      )}
      <Grid
        $cols={1}
        $colwidth={"370px 1fr"}
        $gap={"8px"}
        $padding={"0px 12px"}
      >
        <Column $height={"fit-content"} $padding={"0"}>
          {metricsData.summaryBoxes.map((data, index) => {
            return Array.isArray(data.summaryChildren) &&
              data.summaryChildren.length > 1 ? (
              <SummaryBoxMetricsMulti
                metricsArr={data.metrics as SummaryBoxMetric[][]}
                header={data.header}
                grid={data.grid}
                key={index}
                summaryChildren={data.summaryChildren}
              />
            ) : (
              <SummaryBoxMetrics
                metrics={data.metrics as SummaryBoxMetric[]}
                header={data.header}
                grid={data.grid}
                key={index}
              >
                {data.summaryChildren}
              </SummaryBoxMetrics>
            );
          })}
        </Column>
        <Column $height={"100%"} $padding={"0"}>
          <NetworkSitesBar
            site={site}
            organisation={organisation}
            setShowEnergyCurvesModal={setShowEnergyCurvesModal}
            allowShowSellBar={true}
            margin={"8px 12px"}
          />
          {proposalType === "tenant" ? (
            <YearDayEnergyChart
              header={"Amount of energy procured"}
              chartData={additionalChartData!}
              isGeneratingSite={isGeneratingSite}
              isBatterySite={isBatterySite}
              isNotYetProcuringSite={isNotYetProcuringSite}
              buyEnergyBlock={generateBuyEnergyBlock(false, true)}
              energyProcuredChartPeriod={energyProcuredChartPeriod}
              setEnergyProcuredChartPeriod={setEnergyProcuredChartPeriod}
              height={chartsHeight}
            />
          ) : undefined}
          {metricsData.charts.map((data, index) => (
            <ProposalSiteChart
              header={data.header}
              chartData={data.chartData}
              toolTipText={data.tooltipText}
              currency={data.currency}
              currencyCode={site.currencyCode}
              keys={data.keys}
              barColors={data.barColors}
              height={chartsHeight}
              isGeneratingSite={isGeneratingSite}
              isNotYetProcuringSite={isNotYetProcuringSite}
              buyEnergyBlock={generateBuyEnergyBlock(false, true)}
              key={index}
            />
          ))}
          {proposalType === "landlord" ? (
            <GenerationVsDemandChart
              chartData={additionalChartData! as BarDatum[]}
              keys={["Generation", "Consumption"]}
              height={chartsHeight}
            />
          ) : undefined}
        </Column>
        {showEnergyCurvesModal && (
          <MarketplaceEnergyCurvesModal
            organisation={organisation}
            site={site}
            offerType={isSiteCanSell ? "sell" : "buy"}
            setShowModal={setShowEnergyCurvesModal}
            urlSpillSiteId={urlSpillSiteId}
          />
        )}
      </Grid>
    </>
  );
};

export default ProposalSummary;

const HeaderRow = styled(Row)`
  justify-content: ${(props) => props.$justifyContent};
  align-items: center;
`;
