import { NetworkSite } from "@inrange/building-manager-api-client/models-site";
import "leaflet/dist/leaflet.css";
import React from "react";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import displayId from "../../utils/displayId";
import "./map.css";
import {
  MARKETPLACE_COLOR_BLUE,
  PopupSubHeading,
  PopupText,
  PopupTextHighlighted,
  PopupTextLine,
} from "./marketplace-map-styles";

const NetworkExploreBuyMarker: React.FC<{
  site: NetworkSite;
}> = ({ site }) => {
  return (
    <div>
      <PopupText $fontWeight="0">
        InRange network site {displayId(site.id).substring(0, 3)}
      </PopupText>
      <PopupText $fontWeight="0" $fontSize="10px">
        {displayId(site.id)}
      </PopupText>
      <PopupSubHeading $color={MARKETPLACE_COLOR_BLUE}>
        Energy they can sell
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(site.energyFlowAnnual.exported, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupText
        $fontWeight="0"
        $fontSize="12px"
        style={{ marginTop: "1.33em" }}
      >
        * Location is approximate
      </PopupText>
    </div>
  );
};

export default NetworkExploreBuyMarker;
