import { Site } from "@inrange/building-manager-api-client/models-site";
import { Column, NoPaddedRow, PageCardHeader } from "@inrange/theme-components";
import {
  LeaseComparisonItem,
  LicenseComparisonItem,
} from "@inrange/theme-components/leaseLicenseComparison";
import React from "react";

interface LeaseLicenseChoiceSummaryProps {
  site: Site;
  updateInvestmentModel: (model: "license" | "lease") => void;
  irr?: number;
  ner: number;
  paybackMonths?: number;
  isOwnerOccupier: boolean;
}

const LeaseLicenseChoiceSummary: React.FC<LeaseLicenseChoiceSummaryProps> = ({
  site,
  updateInvestmentModel,
  irr,
  ner,
  paybackMonths,
  isOwnerOccupier,
}) => {
  return (
    <>
      <Column>
        <PageCardHeader
          title="Select an investment model"
          label="This is not a commitment, and you can switch between models as you see fit."
        />
      </Column>
      <NoPaddedRow style={{ marginRight: 34 }}>
        <LicenseComparisonItem
          irr={irr}
          paybackMonths={paybackMonths}
          annualRevenue={
            isOwnerOccupier
              ? site.financialModels.ownerOccupier.license.revenue
              : site.financialModels.landlord.license.revenue
          }
          hardwareCosts={site.projectCosts.hardware}
          installationCosts={site.projectCosts.installation}
          gridConnectionCost={site.costInputsPv.enablingWorksCosts}
          initialInvestment={site.projectCosts.initialInvestment}
          setInvestmentModel={() => {
            updateInvestmentModel("license");
          }}
          currencyCode={site.currencyCode}
        />
        <LeaseComparisonItem
          annualRevenue={
            isOwnerOccupier
              ? site.financialModels.ownerOccupier.lease.revenue
              : site.financialModels.landlord.lease.revenue
          }
          gridConnectionCost={site.costInputsPv.enablingWorksCosts}
          ner={ner}
          setInvestmentModel={() => {
            updateInvestmentModel("lease");
          }}
          currencyCode={site.currencyCode}
        />
      </NoPaddedRow>
    </>
  );
};

export default LeaseLicenseChoiceSummary;
