import React from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatMass from "../../formatting/formatMass";
import formatPercentage from "../../formatting/formatPercentage";
import {
  MetricCardsRow,
  SiteMetricCard,
  TenantSiteMetricValues,
} from "./index";
import { getProcurementNote } from "./utils";

interface TenantSiteMetricCardsProps {
  metrics: TenantSiteMetricValues;
  currencyCode: string;
}

export const TenantSiteMetricCards: React.FC<TenantSiteMetricCardsProps> = ({
  metrics,
  currencyCode,
}) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitted half hourly demand data improves accuracy.";
  const hhDemandDataStringShort = metrics.hhDataUploaded
    ? ""
    : "Submitted HH demand increases accuracy.";
  const procurementNote = getProcurementNote(
    metrics.hasOnsiteBehindMeter,
    metrics.numNetworkImportSites
  );
  return (
    <MetricCardsRow>
      <SiteMetricCard
        title="Procurement cost from InRange in year 1"
        value={formatCurrencyToNearestTen(
          metrics.procurementCostYearOne,
          currencyCode,
          0,
          false
        )}
        tooltipText={`Procured behind the meter demand that can be met by on-site generation and/or the InRange network in year 1, multiplied by your fixed tariff.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Procured energy from InRange year 1"
        value={formatUnitsToNearestTen(metrics.procuredEnergyYearOne, "kWh")}
        note={procurementNote}
        tooltipText={`Projected behind the meter demand that can be met by on-site generation and/or the InRange network in year 1.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Savings in year 1"
        value={formatCurrencyToNearestTen(
          metrics.savingsYearOne,
          currencyCode,
          0,
          false
        )}
        note={`vs. ${(metrics.marketTariff * 100).toFixed(
          2
        )} ${fractionalCurrencySymbol(currencyCode)}/kWh market tariff`}
        tooltipText="The potential savings from the energy procured in year 1, considering the difference between the current market tariff and the InRange tariff."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total demand in year 1"
        value={formatUnitsToNearestTen(metrics.totalDemandYearOne, "kWh")}
        tooltipText={`The total estimated energy demand in the first year. ${hhDemandDataString}`}
        note={hhDemandDataStringShort}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Demand fulfilled by InRange vs. others"
        value={formatPercentage(metrics.demandFulfilledByInRange)}
        note={procurementNote}
        tooltipText={`Projected percentage of overall that will be fulfilled by on-site generation and/or the InRange network instead of by other suppliers. ${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Emissions avoided in year 1"
        value={[
          `${formatMass(metrics.emissionsAvoidedYearOne)} CO2e`,
          `${formatPercentage(metrics.cfeScore)} CFE`,
        ]}
        tooltipText="Emissions avoided in the first year after installation where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at this site location."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
    </MetricCardsRow>
  );
};
