import { Site } from "@inrange/building-manager-api-client/models-site";
import React from "react";
import styled from "styled-components";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import LoadingSpinner from "../LoadingSpinner";
import {
  ColorHighlighted,
  ColorHighlightedWhite,
  Description,
  OfferComparison,
  OfferComparisonSectionAfter,
  OfferDetailHeader,
  OfferDetails,
  OfferDetailSection,
} from "./marketplace-styles";
import YourSiteTodayBeforeBuyOffer from "./YourSiteTodayBeforeBuyOffer";
import YourSiteTodayBeforeSellOffer from "./YourSiteTodayBeforeSellOffer";

const FullHeight = styled.div`
  flex: 1;
  display: flex;
  flex: column;
  align-items: center;
  justify-content: center;
`;

const MarketplaceLoadingBuyOfferDetails: React.FC<{ site: Site }> = ({
  site,
}) => {
  return (
    <>
      <Description data-testid={`marketplace-offer-description-loading-true`}>
        Finding a half-hourly level supply offer for{" "}
        {formatUnitsToNearestTen(site.energyFlowAnnual.gridImport, "kWh")} of
        your {"site's"} unmet demand...
      </Description>
      <OfferDetails>
        <OfferDetailSection>
          <LoadingSpinner $border={"solid #2879a7"} />
        </OfferDetailSection>
      </OfferDetails>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        How would <ColorHighlighted>accepting this offer</ColorHighlighted>{" "}
        impact your {"site's"} financial performance?
      </Description>
      <OfferComparison>
        <YourSiteTodayBeforeBuyOffer site={site} />
        <OfferComparisonSectionAfter>
          <OfferDetailHeader>
            <ColorHighlightedWhite>
              Your site if this offer is accepted
            </ColorHighlightedWhite>
          </OfferDetailHeader>
          <FullHeight>
            <LoadingSpinner />
          </FullHeight>
        </OfferComparisonSectionAfter>
      </OfferComparison>
    </>
  );
};

const MarketplaceLoadingSellOfferDetails: React.FC<{ site: Site }> = ({
  site,
}) => {
  return (
    <>
      <Description data-testid={`marketplace-offer-description-loading-true`}>
        Finding a half-hourly level sale offer for{" "}
        {formatUnitsToNearestTen(site.energyFlowAnnual.exported, "kWh")} of your{" "}
        {"site's"} excess energy...
      </Description>
      <OfferDetails>
        <OfferDetailSection>
          <LoadingSpinner $border={"solid #2879a7"} />
        </OfferDetailSection>
      </OfferDetails>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        How would <ColorHighlighted>accepting this offer</ColorHighlighted>{" "}
        impact your {"site's"} financial performance?
      </Description>
      <OfferComparison>
        <YourSiteTodayBeforeSellOffer site={site} renderBlankOfferRow={true} />
        <OfferComparisonSectionAfter>
          <ColorHighlightedWhite>
            Your site if this offer is accepted
          </ColorHighlightedWhite>
          <FullHeight>
            <LoadingSpinner />
          </FullHeight>
        </OfferComparisonSectionAfter>
      </OfferComparison>
    </>
  );
};

const MarketplaceLoadingOfferDetails: React.FC<{
  site: Site;
  type: string;
}> = ({ site, type }) => {
  if (type === "buy") {
    return <MarketplaceLoadingBuyOfferDetails site={site} />;
  } else {
    return <MarketplaceLoadingSellOfferDetails site={site} />;
  }
};

export default MarketplaceLoadingOfferDetails;
