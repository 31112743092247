import { Site } from "@inrange/building-manager-api-client/models-site";
import React, { useState } from "react";
import {
  Confirmation,
  Description,
  MarketplaceButton,
  NoOfferComparison,
} from "./marketplace-styles";
import YourSiteTodayBeforeBuyOffer from "./YourSiteTodayBeforeBuyOffer";
import YourSiteTodayBeforeSellOffer from "./YourSiteTodayBeforeSellOffer";

const MarketplaceNoOfferDetails: React.FC<{
  site: Site;
  type: string;
  acceptOfferFn: () => void;
}> = ({ site, type, acceptOfferFn }) => {
  const [acceptedOffer, setAcceptedOffer] = useState(false);
  return (
    <>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        {type === "buy"
          ? `We weren't able to find an additional Marketplace offer automatically.  Contact us and we'll work together to find the right offer for your needs!`
          : `You've already got a great offer! We weren't able to find an additional Marketplace offer automatically.  Contact us to explore other selling opportunities.`}
      </Description>
      <NoOfferComparison>
        {type === "buy" && <YourSiteTodayBeforeBuyOffer site={site as Site} />}
        {type === "sell" && (
          <YourSiteTodayBeforeSellOffer
            site={site as Site}
            renderBlankOfferRow={false}
          />
        )}
        {!acceptedOffer && (
          <MarketplaceButton
            height="60px"
            $width="200px"
            fontWeight="400"
            fontSize="14px"
            label={
              type === "buy"
                ? `Help me find an offer to buy more energy`
                : `Help me find a better offer to sell energy`
            }
            onClick={() => {
              setAcceptedOffer(true);
              acceptOfferFn();
            }}
            data-testid="marketplace-accept-offer-button"
          />
        )}
        {acceptedOffer && (
          <>
            <Confirmation>
              {type === "buy"
                ? "A member of our team will reach out shortly to explore other procurement opportunities."
                : "A member of our team will reach out shortly to explore other selling opportunities."}
            </Confirmation>
            <MarketplaceButton
              height="60px"
              $width="200px"
              fontWeight="400"
              fontSize="14px"
              fontColor={"#000"}
              label={"Your request was sent"}
              disabled={true}
            />
          </>
        )}
      </NoOfferComparison>
    </>
  );
};

export default MarketplaceNoOfferDetails;
