import {
  OrgSiteListEntry,
  SimplifiedSdmMatchConfig,
} from "@inrange/building-manager-api-client/models-organisation";
import { NetworkSite } from "@inrange/building-manager-api-client/models-site";
import "leaflet/dist/leaflet.css";
import React from "react";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import displayId from "../../utils/displayId";
import "./map.css";
import {
  MARKETPLACE_COLOR_YELLOW,
  PopupSubHeading,
  PopupText,
  PopupTextHighlighted,
  PopupTextLine,
} from "./marketplace-map-styles";

const NetworkExploreExistingBuysMarker: React.FC<{
  site: NetworkSite;
  existingSells: SimplifiedSdmMatchConfig[];
  orgSitesById: Record<string, OrgSiteListEntry>;
}> = ({ site, existingSells, orgSitesById }) => {
  return (
    <div>
      <PopupText $fontWeight="0">
        InRange network site {displayId(site.id).substring(0, 3)}
      </PopupText>
      <PopupText $fontWeight="0" $fontSize="10px">
        {displayId(site.id)}
      </PopupText>
      <PopupSubHeading $color={MARKETPLACE_COLOR_YELLOW}>
        Selling to {existingSells.length} of your sites
      </PopupSubHeading>
      {existingSells.map((sell) => (
        <PopupTextLine key={sell.buyerId}>
          <PopupTextHighlighted>
            {formatUnitsToNearestTen(sell.volume, "kWh")}
          </PopupTextHighlighted>{" "}
          / year to{" "}
          <PopupTextHighlighted>
            {orgSitesById[sell.buyerId].name}
          </PopupTextHighlighted>
        </PopupTextLine>
      ))}
      <PopupSubHeading $color={MARKETPLACE_COLOR_YELLOW}>
        Remaining energy they can sell
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(site.energyFlowAnnual.exported, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupText
        $fontWeight="0"
        $fontSize="12px"
        style={{ marginTop: "1.33em" }}
      >
        * Location is approximate
      </PopupText>
    </div>
  );
};

export default NetworkExploreExistingBuysMarker;
