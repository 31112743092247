import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQueries, useQuery, UseQueryOptions } from "react-query";
import getNetworkSite from "../http/getNetworkSite";
import getNetworkSiteList from "../http/getNetworkSiteList";
import { NetworkSite } from "../models/site";

const useNetworkSiteList = (
  orgId: string | undefined,
  enabled: boolean,
  onlyLinkedNetwork: boolean,
  userOrgId?: string
) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchNetworkSiteList = useQuery<
    {
      sites: NetworkSite[];
    },
    Error
  >(
    ["org", orgId, "networksites"],
    async () => {
      return getNetworkSiteList(
        await getToken(),
        orgId,
        onlyLinkedNetwork,
        userOrgId
      );
    },
    { enabled: enabled && !!orgId, staleTime: 60_000 }
  );

  const chunk = <T>(arr: T[], chunkSize: number): T[][] => {
    const chunks: T[][] = [];
    const tmp = [...arr];
    while (tmp.length) {
      chunks.push(tmp.splice(0, chunkSize));
    }
    return chunks;
  };

  const useNetworkSiteData = (sitesIds: string[]) => {
    return useQueries<UseQueryOptions<{ sites: NetworkSite[] }>[]>(
      chunk(sitesIds, 10)
        .map((networkSiteIdsChunk) => networkSiteIdsChunk.join(","))
        .map((networkSiteIdsChunkStr) => {
          return {
            queryKey: ["org", orgId, "networksites", networkSiteIdsChunkStr],
            queryFn: async () =>
              getNetworkSite(await getToken(), orgId, networkSiteIdsChunkStr),
            staleTime: 5 * 60 * 1000,
            enabled: enabled && !!orgId,
          };
        })
    );
  };

  const networkSites = useNetworkSiteData(
    (fetchNetworkSiteList.data?.sites || []).map(
      (networkSite) => networkSite.id
    )
  );

  return {
    fetchNetworkSiteList,
    networkSites,
    useNetworkSiteData,
  };
};

export default useNetworkSiteList;
