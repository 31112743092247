import {
  Organisation,
  OrgSiteListEntry,
  SimplifiedSdmMatchConfig,
} from "@inrange/building-manager-api-client/models-organisation";
import "leaflet/dist/leaflet.css";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import SimpleButton from "../buttons/SimpleButton";
import { RowNoDefaults } from "../layout/Flex";
import { siteCanBuy } from "../marketplace/marketplace-utils";
import "./map.css";
import {
  ForceWhiteText,
  MARKETPLACE_COLOR_PURPLE,
  PopupSubHeading,
  PopupText,
  PopupTextHighlighted,
  PopupTextLine,
} from "./marketplace-map-styles";
import RelatedOwnershipLine from "./RelatedOwnershipLine";

const MySiteBuyExistingBuysMarker: React.FC<{
  org: Organisation;
  site: OrgSiteListEntry;
  existingSells: SimplifiedSdmMatchConfig[];
  orgSitesById: Record<string, OrgSiteListEntry>;
}> = ({ org, site, existingSells, orgSitesById }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlSpillSiteId = params.get("spillSiteId") || undefined;

  const sdmMatches = "sdmMatches" in site ? site.sdmMatches : [];
  const numNetworkImportSites = sdmMatches.filter(
    (match) => match.buyerId === site.id
  ).length;

  return (
    <div>
      <PopupText
        $fontWeight="0"
        $clickable={true}
        onClick={() => {
          navigate(`/org/${org.id}/site/${site.id}`);
        }}
      >
        {site.name}
      </PopupText>
      <PopupSubHeading $color={MARKETPLACE_COLOR_PURPLE}>
        Selling to {existingSells.length} of your sites
      </PopupSubHeading>
      {existingSells.map((sell) => (
        <PopupTextLine key={sell.buyerId}>
          <PopupTextHighlighted>
            {formatUnitsToNearestTen(sell.volume, "kWh")}
          </PopupTextHighlighted>{" "}
          / year to{" "}
          <PopupTextHighlighted>
            {orgSitesById[sell.buyerId].name}
          </PopupTextHighlighted>
        </PopupTextLine>
      ))}
      <PopupSubHeading $color={MARKETPLACE_COLOR_PURPLE}>
        Remaining energy they can sell
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(site.energyFlowAnnual.exported, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupSubHeading $color={MARKETPLACE_COLOR_PURPLE}>
        Buying from
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>{numNetworkImportSites}</PopupTextHighlighted>{" "}
        network site{numNetworkImportSites === 1 ? "" : "s"}
      </PopupTextLine>
      <PopupSubHeading $color={MARKETPLACE_COLOR_PURPLE}>
        Remaining unmet demand
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(site.energyFlowAnnual.gridImport, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupSubHeading $color={MARKETPLACE_COLOR_PURPLE}>
        InRange network tariff
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {(site.networkImportTariff * 100).toFixed(2)}
          {fractionalCurrencySymbol(site.currencyCode)}
        </PopupTextHighlighted>{" "}
        / kWh
      </PopupTextLine>
      <RelatedOwnershipLine org={org} site={site} />
      {siteCanBuy(org, site) && (
        <ForceWhiteText>
          <RowNoDefaults $justifyContent="center" $margin="15px 0">
            <SimpleButton
              fontWeight="400"
              label={"Get an offer"}
              width={"60%"}
              to={`/org/${org.id}/marketplace?siteId=${site.id}&offerType=buy${urlSpillSiteId ? `&spillSiteId=${urlSpillSiteId}` : ""}`}
              background={"#a2a6ff"}
            />
          </RowNoDefaults>
        </ForceWhiteText>
      )}
    </div>
  );
};

export default MySiteBuyExistingBuysMarker;
