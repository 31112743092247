import axiosConfig from "./axiosConfig";

const getNetworkSiteList = async (
  authToken,
  orgId,
  onlyLinkedNetwork,
  userOrgId
) => {
  const queryArgs = [];
  if (userOrgId) {
    queryArgs.push(`userOrgId=${userOrgId}`);
  }
  if (onlyLinkedNetwork) {
    queryArgs.push("onlyLinkedNetwork=true");
  }
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}/network_site${
      queryArgs.length > 0 ? `?${queryArgs.join("&")}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getNetworkSiteList;
