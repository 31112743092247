import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import React, { useState } from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import roundCurrency from "../../formatting/roundCurrency";
import displayId from "../../utils/displayId";
import { Row } from "../layout/Flex";
import {
  ColorHighlighted,
  ColorHighlightedWhite,
  Confirmation,
  Description,
  InheritColorLink,
  MarketplaceButton,
  OfferComparison,
  OfferComparisonSectionAfter,
  OfferDetailHeader,
  OfferDetails,
  OfferDetailSection,
} from "./marketplace-styles";
import { getSavings } from "./marketplace-utils";
import MarketplaceEnergyCurvesModal from "./MarketplaceEnergyCurvesModal";
import YourSiteTodayBeforeBuyOffer from "./YourSiteTodayBeforeBuyOffer";

interface MarketplaceBuyOfferDetailsProps {
  org: Organisation;
  site: Site;
  sdmOffer: SdmOffer;
  offerSite: OrgSiteListEntry | NetworkSite;
  userEmail: string;
  acceptOfferFn: () => void;
}

const MarketplaceBuyOfferDetails: React.FC<MarketplaceBuyOfferDetailsProps> = ({
  org,
  site,
  sdmOffer,
  offerSite,
  userEmail,
  acceptOfferFn,
}) => {
  const [acceptedOffer, setAcceptedOffer] = useState(false);
  const [showEnergyCurvesModal, setShowEnergyCurvesModal] =
    useState<boolean>(false);

  const onViewEnergyShape = () => {
    setShowEnergyCurvesModal(true);
    trackUserInteraction(
      {
        site_id: site!.id,
        site_name: site!.name,
        site_operational_status: site!.operationalStatus,
        network_site_id: offerSite!.id,
      },
      "ENERGY_OFFER",
      "VIEWED_ENERGY_SHAPE",
      userEmail,
      "customer-app"
    );
  };

  const hasOnsite = site.energyFlowAnnual.behindMeter > 0;
  const numNetworkImportSites = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  ).length;

  return (
    <>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        {"We've"} found a site on the InRange network{" "}
        <ColorHighlighted>
          offering to sell your site more energy
        </ColorHighlighted>
        , increasing your savings to{" "}
        <ColorHighlighted>
          {formatCurrencyToNearestTen(
            roundCurrency(getSavings(site, sdmOffer!.financialModels)),
            site.currencyCode,
            0,
            false
          )}{" "}
          a year.
        </ColorHighlighted>
      </Description>
      <OfferDetails>
        <OfferDetailSection>
          <OfferDetailHeader>
            <ColorHighlighted>Offer terms</ColorHighlighted>
          </OfferDetailHeader>
          <div>
            <b>Amount:</b>{" "}
            {formatUnitsToNearestTen(sdmOffer.config.volume, "kWh")} / year
            <InheritColorLink
              href="#"
              onClick={onViewEnergyShape}
              style={{ paddingLeft: "12px" }}
            >
              View energy profile
            </InheritColorLink>
          </div>
          <div>
            <b>Price:</b> {(sdmOffer.config.tariff * 100).toFixed(2)}
            {fractionalCurrencySymbol(site.currencyCode)} / kWh fixed for{" "}
            {sdmOffer.config.ppaLength} years
          </div>
        </OfferDetailSection>
        <OfferDetailSection>
          <OfferDetailHeader>
            <ColorHighlighted>Selling site</ColorHighlighted>
          </OfferDetailHeader>
          {!(offerSite as NetworkSite).isNetwork && (
            <div>
              <b>Name:</b> {(offerSite as OrgSiteListEntry).name}
            </div>
          )}
          {(offerSite as NetworkSite).isNetwork && (
            <div>
              <b>ID:</b> {displayId(sdmOffer.config.sellerId)}
            </div>
          )}
          <div>
            <b>Distance:</b> {sdmOffer.dest.km} km
          </div>
        </OfferDetailSection>
      </OfferDetails>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        How would <ColorHighlighted>accepting this offer</ColorHighlighted>{" "}
        impact your {"site's"} financial performance?
      </Description>
      <OfferComparison>
        <YourSiteTodayBeforeBuyOffer site={site} />
        <OfferComparisonSectionAfter>
          <OfferDetailHeader>
            <ColorHighlightedWhite>
              Your site if this offer is accepted
            </ColorHighlightedWhite>
          </OfferDetailHeader>
          <div>
            <b>
              {formatUnitsToNearestTen(
                site.energyFlowAnnual.networkImport + sdmOffer.config.volume,
                "kWh"
              )}
            </b>{" "}
            bought from{" "}
            <InheritColorLink href="#" onClick={onViewEnergyShape}>
              {numNetworkImportSites === 0
                ? "1 site"
                : `${numNetworkImportSites + 1} sites`}
            </InheritColorLink>{" "}
            <b>
              @ {(site.networkImportTariff * 100).toFixed(2)}
              {fractionalCurrencySymbol(site.currencyCode)}
            </b>
          </div>
          {hasOnsite && (
            <div>
              {formatUnitsToNearestTen(
                site.energyFlowAnnual.behindMeter,
                "kWh"
              )}{" "}
              bought on-site @ {(site.tenantTariff * 100).toFixed(2)}
              {fractionalCurrencySymbol(site.currencyCode)}
            </div>
          )}
          <div>
            <b>
              {formatCurrencyToNearestTen(
                roundCurrency(getSavings(site, sdmOffer.financialModels)),
                site.currencyCode,
                0,
                false
              )}
            </b>{" "}
            saved / year
          </div>
          <div>
            <b>
              {formatPercentage(
                (site.energyFlowAnnual.behindMeter +
                  site.energyFlowAnnual.networkImport +
                  sdmOffer.config.volume) /
                  site.energyFlowAnnual.demand
              )}
            </b>{" "}
            of total demand fulfilled by InRange
          </div>
        </OfferComparisonSectionAfter>
      </OfferComparison>
      <Row
        $padding={"0"}
        $margin={"10px 0 0 0"}
        $justifyContent={"end"}
        $alignItems={"center"}
      >
        {!acceptedOffer && sdmOffer && (
          <>
            <MarketplaceButton
              height="60px"
              $width="200px"
              fontWeight="400"
              fontSize="14px"
              label={
                numNetworkImportSites === 0
                  ? "View selling site's energy profile"
                  : "View energy profiles of all sites selling to this site"
              }
              onClick={onViewEnergyShape}
              background="#fff"
              borderColor="2px #00022D"
              fontColor="#00022D"
            />
          </>
        )}
        {!acceptedOffer && (
          <MarketplaceButton
            height="60px"
            $width="200px"
            fontWeight="400"
            fontSize="14px"
            label={`Accept offer / tell me more!`}
            onClick={() => {
              setAcceptedOffer(true);
              acceptOfferFn();
            }}
            data-testid="marketplace-accept-offer-button"
          />
        )}
        {acceptedOffer && (
          <>
            <Confirmation>{`A member of our team will reach out shortly to complete your offer request.`}</Confirmation>
            <MarketplaceButton
              height="60px"
              $width="200px"
              fontWeight="400"
              fontSize="14px"
              fontColor={"#000"}
              label={"Your request was sent"}
              disabled={true}
            />
          </>
        )}
      </Row>
      {showEnergyCurvesModal && (
        <MarketplaceEnergyCurvesModal
          organisation={org}
          site={site}
          offerType={"buy"}
          setShowModal={setShowEnergyCurvesModal}
          extraSdmBuyOffer={sdmOffer}
          urlSpillSiteId={undefined}
        />
      )}
    </>
  );
};

export default MarketplaceBuyOfferDetails;
