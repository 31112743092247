import { Site } from "@inrange/building-manager-api-client/models-site";
import React from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import {
  ColorHighlighted,
  OfferComparisonSectionBefore,
  OfferDetailHeader,
} from "./marketplace-styles";
import { getSavings } from "./marketplace-utils";

export const YourSiteTodayBeforeBuyOffer: React.FC<{
  site: Site;
}> = ({ site }) => {
  const hasOnsite = site.energyFlowAnnual.behindMeter > 0;
  const numNetworkImportSites = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  ).length;
  return (
    <OfferComparisonSectionBefore>
      <OfferDetailHeader>
        <ColorHighlighted>Your site today</ColorHighlighted>
      </OfferDetailHeader>
      <div>
        {formatUnitsToNearestTen(site.energyFlowAnnual.networkImport, "kWh")}{" "}
        bought from{" "}
        {numNetworkImportSites === 0
          ? "the network"
          : numNetworkImportSites === 1
            ? "1 site"
            : `${numNetworkImportSites} sites`}{" "}
        @ {(site.networkImportTariff * 100).toFixed(2)}
        {fractionalCurrencySymbol(site.currencyCode)}
      </div>
      {hasOnsite && (
        <div>
          {formatUnitsToNearestTen(site.energyFlowAnnual.behindMeter, "kWh")}{" "}
          bought on-site @ {(site.tenantTariff * 100).toFixed(2)}
          {fractionalCurrencySymbol(site.currencyCode)}
        </div>
      )}
      <div>
        {formatCurrencyToNearestTen(
          getSavings(site, site.financialModels),
          site.currencyCode,
          0,
          false
        )}{" "}
        saved / year
      </div>
      <div>
        {formatPercentage(
          (site.energyFlowAnnual.behindMeter +
            site.energyFlowAnnual.networkImport) /
            site.energyFlowAnnual.demand
        )}{" "}
        of total demand fulfilled by InRange
      </div>
    </OfferComparisonSectionBefore>
  );
};

export default YourSiteTodayBeforeBuyOffer;
