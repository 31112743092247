import { SiteEnergyFlowGraphData } from "@inrange/building-manager-api-client/models-site";
import toNearestTen from "../../formatting/toNearestTen";
import { ChartData } from "../charts/chart-types";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatMatchedEnergyChartData = (
  energyFlowGraphData: SiteEnergyFlowGraphData,
  period: string
): ChartData[] => {
  if (period === "year")
    return formatMatchedEnergyChartDataYear(energyFlowGraphData);
  return formatMatchedEnergyChartDataDay(energyFlowGraphData, period);
};

const formatMatchedEnergyChartDataDay = (
  energyFlowGraphData: SiteEnergyFlowGraphData,
  period: string
): ChartData[] => {
  const chartData: ChartData[] = [];

  const importDay = period === "summer" ? "summerDay" : "winterDay";

  if (!energyFlowGraphData.match[importDay]) return chartData;

  let hour = 0;
  let minute = 0;
  for (
    let index = 0;
    index < energyFlowGraphData.match[importDay].length;
    index++
  ) {
    const importKwh = energyFlowGraphData.match[importDay][index];
    const hourText = hour.toString().padStart(2, "0");
    const minuteText = minute.toString().padStart(2, "0");
    chartData.push({
      index: `${hourText}:${minuteText}`,
      Match: Math.round(importKwh),
      ...(energyFlowGraphData.total
        ? { Total: Math.round(energyFlowGraphData.total[importDay][index]) }
        : {}),
    });
    if (minute === 30) {
      minute = 0;
      hour += 1;
    } else {
      minute += 30;
    }
  }

  return chartData;
};

const formatMatchedEnergyChartDataYear = (
  energyFlowGraphData: SiteEnergyFlowGraphData
): ChartData[] => {
  const oneMonthChartData = (monthIx: number): ChartData => {
    return {
      index: months[monthIx - 1],
      Match: toNearestTen(energyFlowGraphData.match.monthly[monthIx]),
      ...(energyFlowGraphData.total
        ? { Total: toNearestTen(energyFlowGraphData.total.monthly[monthIx]) }
        : {}),
    };
  };

  const result: ChartData[] = [];
  for (let i = 1; i <= 12; i++) {
    result.push(oneMonthChartData(i));
  }
  return result;
};
